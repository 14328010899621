import React from "react";
import { Typography } from "@material-ui/core";

import Layout from "../components/Layout";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Typography variant="h1">Hi people</Typography>
    <Typography variant="body1">Welcome to my site.</Typography>
    <Typography variant="body2">
      Now I gotta actually get stuff to put in here
    </Typography>
  </Layout>
);

export default IndexPage;
